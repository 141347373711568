import React from 'react';
import './App.css';
import {Accueil, Contact, Societes} from "./page";

function App() {
  return (
      <main className={"flex justify-center flex-col font-sans"}>
          <Accueil/>
          <Societes/>
          <Contact/>
      </main>
  );
}

export default App;
